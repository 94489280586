import {gql} from '@apollo/client';

export const CHECK_CODE = gql`
  mutation UpdateOtpUser(
    $id: Int!
    $phone_number: String!
    $unique_code: String!
    $otp_code: Int!
  ) {
    update_otp_code(
      id: $id
      phone_number: $phone_number
      unique_code: $unique_code
      otp_code: $otp_code
    ) {
      accessToken
      refreshToken
    }
  }
`;
