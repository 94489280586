import React from 'react';
import {Linking} from 'react-native';

import {
  Padding,
  Margin,
  useThemeContext,
  H3,
  TextBtn,
  PaddingVertical,
  Icon,
  Row,
  Center,
  CenterRight,
  MarginHorizontal,
} from '@servesall/atoms';

function Terms({navigation}) {
  const {color2, color1, color9, color7} = useThemeContext();
  return (
    <Margin>
      <Padding>
        <TextBtn
          onClick={() => Linking.openURL('https://dev.servesall.com')}
          borderColorActive={color9}
          borderColorIdle={color7}>
          <PaddingVertical>
            <Row style={{justifyContent: 'space-between'}}>
              <H3>Terms & conditions</H3>
              <CenterRight>
                <MarginHorizontal>
                  <Icon
                    icon="next"
                    autoplay={false}
                    loop={false}
                    color={color2}
                  />
                </MarginHorizontal>
              </CenterRight>
            </Row>
          </PaddingVertical>
        </TextBtn>
      </Padding>
    </Margin>
  );
}

export default Terms;
