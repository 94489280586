import React, {useEffect, useState} from 'react';
import {
  Link,
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import logo from '../../logo.svg';

import {
  Header,
  Padding,
  Stretch,
  CenterRight,
  CenterLeft,
  H2,
  H3,
  TextBtn,
  Row,
  useThemeContext,
  MarginHorizontal,
} from '@servesall/atoms';

const Headers = ({children}) => {
  let history = useHistory();
  const [currentRoute, setCurrentRoute] = useState(history.location.pathname);
  const theme = useThemeContext();

  return (
    <Header height={100} style={{maxHeight: 100}}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          theme,
          history,
          currentRoute,
          setCurrentRoute,
        }),
      )}
    </Header>
  );
};

Headers.Auth = ({theme, history, currentRoute, setCurrentRoute}) => {
  const {color9, color7} = theme;
  return (
    <Stretch>
      <CenterLeft direction="row">
        <Padding>
          <Row style={{alignItems: 'center'}}>
            <MarginHorizontal>
              <img src={logo} className="App-logo" alt="logo" />
            </MarginHorizontal>
            <H2>ServesAll</H2>
          </Row>
        </Padding>
      </CenterLeft>
      <CenterRight direction="row">
        <Padding>
          <MarginHorizontal>
            <TextBtn
              active={currentRoute === '/login'}
              onClick={() => {
                history.push('/login');
                setCurrentRoute('/login');
              }}
              borderColorActive={color9}
              borderColorIdle={color7}>
              <Padding>
                <H3>Login</H3>
              </Padding>
            </TextBtn>
          </MarginHorizontal>
        </Padding>
        <Padding>
          <MarginHorizontal>
            <TextBtn
              active={currentRoute === '/register'}
              onClick={() => {
                history.push('/register');
                setCurrentRoute('/register');
              }}
              borderColorActive={color9}
              borderColorIdle={color7}>
              <Padding>
                <H3>Register</H3>
              </Padding>
            </TextBtn>
          </MarginHorizontal>
        </Padding>
      </CenterRight>
    </Stretch>
  );
};

Headers.StartMerchant = ({theme, history, currentRoute, setCurrentRoute}) => {
  const {color9, color7} = theme;
  return (
    <Stretch>
      <CenterLeft direction="row">
        <Padding>
          <Row style={{alignItems: 'center'}}>
            <MarginHorizontal>
              <img src={logo} className="App-logo" alt="logo" />
            </MarginHorizontal>
            <H2>ServesAll</H2>
            <Padding>
              <MarginHorizontal>
                <TextBtn
                  active={currentRoute === '/startmerchant'}
                  onClick={() => {
                    history.push('/startmerchant');
                    setCurrentRoute('/startmerchant');
                  }}
                  borderColorActive={color9}
                  borderColorIdle={color7}>
                  <Padding>
                    <H3>Setup Merchant</H3>
                  </Padding>
                </TextBtn>
              </MarginHorizontal>
            </Padding>
          </Row>
        </Padding>
      </CenterLeft>
      <CenterRight direction="row">
        <Padding>
          <MarginHorizontal>
            <TextBtn
              active={currentRoute === '/account'}
              onClick={() => {
                history.push('/account');
                setCurrentRoute('/account');
              }}
              borderColorActive={color9}
              borderColorIdle={color7}>
              <Padding>
                <H3>My Account</H3>
              </Padding>
            </TextBtn>
          </MarginHorizontal>
        </Padding>
      </CenterRight>
    </Stretch>
  );
};

Headers.Setup = ({theme, history, currentRoute, setCurrentRoute}) => {
  const {color9, color7} = theme;
  return (
    <Stretch>
      <CenterLeft direction="row">
        <Padding>
          <Row style={{alignItems: 'center'}}>
            <MarginHorizontal>
              <img src={logo} className="App-logo" alt="logo" />
            </MarginHorizontal>
            <H2>ServesAll</H2>
            <Padding>
              <MarginHorizontal>
                <TextBtn
                  active={currentRoute === '/setupmerchant'}
                  onClick={() => {
                    history.push('/setupmerchant');
                    setCurrentRoute('/setupmerchant');
                  }}
                  borderColorActive={color9}
                  borderColorIdle={color7}>
                  <Padding>
                    <H3>Setup Merchant</H3>
                  </Padding>
                </TextBtn>
              </MarginHorizontal>
            </Padding>
          </Row>
        </Padding>
      </CenterLeft>
      <CenterRight direction="row">
        <Padding>
          <MarginHorizontal>
            <TextBtn
              active={currentRoute === '/account'}
              onClick={() => {
                history.push('/account');
                setCurrentRoute('/account');
              }}
              borderColorActive={color9}
              borderColorIdle={color7}>
              <Padding>
                <H3>My Account</H3>
              </Padding>
            </TextBtn>
          </MarginHorizontal>
        </Padding>
      </CenterRight>
    </Stretch>
  );
};

export default Headers;
