import React, {useRef, useEffect} from 'react';
import {SafeAreaView, View} from 'react-native';
import {
  CenterLeft,
  Padding,
  PaddingHorizontal,
  Margin,
  MarginVertical,
  MarginHorizontal,
  MarginTop,
  H1,
  useThemeContext,
  Stretch,
  InputOtp,
  FullScreen,
  Center,
  Loader,
  H2,
  Row,
  CenterRight,
} from '@servesall/atoms';
import {AccordionScroller} from '@servesall/molecules';
import Name from './Name';
import Category from './Category';
import Location from './Location';
import Media from './Media';
import Calendar from './Calendar';
import TimeSlots from './TimeSlots';

export default function Profile() {
  const {color1, color2, margin} = useThemeContext();

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: color1}}>
      <MarginHorizontal style={{flex: 1}}>
        <AccordionScroller style={{paddingTop: 30}}>
          <Name />
          <Category />
          <Location />
          <Media />
          <Calendar />
          <TimeSlots />
        </AccordionScroller>
      </MarginHorizontal>
    </SafeAreaView>
  );
}
