import {gql} from '@apollo/client';

export const CREATE_USER_GRAPH = gql`
  mutation CreateUser($input: UserInput!) {
    create_user(input: $input) {
      id
      unique_code
      full_phone_number
    }
  }
`;
