import React, {useRef, useEffect, useState} from 'react';
import {SafeAreaView, View} from 'react-native';
import {
  CenterLeft,
  Padding,
  PaddingHorizontal,
  Margin,
  MarginVertical,
  MarginHorizontal,
  MarginTop,
  H1,
  useThemeContext,
  Stretch,
  InputOtp,
  FullScreen,
  Center,
  Loader,
  H2,
  Row,
  CenterRight,
} from '@servesall/atoms';
import {AccordionItem} from '@servesall/molecules';
import calendarActive from '../Assets/calendar.json';
import openClose from '../Assets/openClose.json';
import LottieView from 'lottie-react-native';

export default function Name() {
  const {color1, color7, margin} = useThemeContext();
  const [openItem, setOpenItem] = useState(false);
  const openRef = useRef();
  const lottieRef = useRef();
  useEffect(() => {
    if (openItem) {
      openRef.current?.play(0, 20);
      lottieRef.current?.play();
    } else {
      openRef.current?.play(20, 33);
      lottieRef.current?.reset();
    }
  }, [openItem]);
  return (
    <AccordionItem eventKey="4" onChange={(isOpen) => setOpenItem(isOpen)}>
      <AccordionItem.Head style={{borderBottomWidth: 1, borderColor: color7}}>
        <MarginTop>
          <PaddingHorizontal>
            <Row>
              <CenterLeft direction="row">
                <LottieView
                  style={{
                    height: 70,
                  }}
                  ref={lottieRef}
                  autoPlay={true}
                  loop={false}
                  source={calendarActive}
                />

                <MarginTop>
                  <MarginHorizontal>
                    <H2>Calendar</H2>
                  </MarginHorizontal>
                </MarginTop>
              </CenterLeft>
              <CenterRight direction="row">
                <MarginTop>
                  <MarginHorizontal>
                    <LottieView
                      style={{
                        height: 18,
                      }}
                      ref={openRef}
                      autoPlay={false}
                      loop={false}
                      source={openClose}
                    />
                  </MarginHorizontal>
                </MarginTop>
              </CenterRight>
            </Row>
          </PaddingHorizontal>
        </MarginTop>
      </AccordionItem.Head>
      <AccordionItem.Body>
        <View>
          <Padding>
            <MarginHorizontal>
              <H1>Text</H1>
            </MarginHorizontal>
          </Padding>
        </View>
      </AccordionItem.Body>
    </AccordionItem>
  );
}
