import React, {useEffect} from 'react';
import {SafeAreaView, StatusBar, View, ScrollView} from 'react-native';
import {
  CenterLeft,
  Padding,
  Margin,
  MarginVertical,
  H1,
  useThemeContext,
  Stretch,
  InputOtp,
  FullScreen,
  Center,
  Loader,
  H2,
  WebSmallWrapper,
} from '@servesall/atoms';
import {useForm, Controller} from 'react-hook-form';
import {CHECK_CODE} from './Otp.graph';
import useSubmitHook from 'Hooks/SubmitForm';
import {useAuthUpdater, set_tokens} from '@servesall/organisms';
import {useHistory} from 'react-router-dom';

export default function Otp(props) {
  let history = useHistory();
  const {full_phone_number, id, unique_code} = props.location.state;
  const {color7, color2, color1, color6} = useThemeContext();
  const authSetter = useAuthUpdater();

  const {control, errors} = useForm({
    mode: 'onChange',
  });

  const {submitForm, loading, error, data} = useSubmitHook({
    gql: CHECK_CODE,
    formState: {isValid: true, isDirty: true},
    extract: 'update_otp_code',
  });

  useEffect(() => {
    if (data.accessToken) {
      set_tokens({
        access_token: data.accessToken,
        refresh_token: data.refreshToken,
      }).then(() => {
        authSetter({isLoggedIn: true, mustRegister: false, networkCheck: true});
        history.push({
          pathname: '/',
        });
      });
    }
  }, [data]);

  return (
    <View style={{flex: 1, backgroundColor: color1}}>
      <Center style={{flex: 1}}>
        <WebSmallWrapper>
          {loading && (
            <FullScreen style={{backgroundColor: color1}}>
              <Center>
                <Loader color={color2} />
              </Center>
            </FullScreen>
          )}
          <CenterLeft style={{flex: 1}}>
            <Stretch>
              <Margin>
                <MarginVertical>
                  <Padding>
                    <H1>Enter the</H1>
                    <H1>code sent by SMS</H1>
                  </Padding>
                </MarginVertical>
                {error && (
                  <Padding>
                    <H2 color={color6}>{error}</H2>
                  </Padding>
                )}
                <Controller
                  name="otp"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[0-9]+$/g,
                  }}
                  defaultValue={null}
                  render={({onChange, value, ref}) => (
                    <Padding>
                      <InputOtp
                        error="Invalid code"
                        autoFocus={true}
                        submitCode={(code) => {
                          submitForm({
                            data: {
                              phone_number: full_phone_number,
                              id,
                              unique_code,
                              otp_code: parseInt(code.join('')),
                            },
                          });
                        }}
                        textChange={(value) => onChange(value)}
                        hasError={errors.otp}
                        keyboardType="number-pad"
                        borderColorIdle={color7}
                        borderColorActive={color2}
                        borderColorError={color6}
                      />
                    </Padding>
                  )}
                />
              </Margin>
            </Stretch>
          </CenterLeft>
        </WebSmallWrapper>
      </Center>
    </View>
  );
}
