import {useState, useEffect} from 'react';
import {useMutation} from '@apollo/react-hooks';

export default function useSubmitHook({gql, formState, extract}) {
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);

  const {isValid, isDirty} = formState;
  const [mutation, {loading}] = useMutation(gql);

  const submitForm = ({data}) => {
    isValid &&
      isDirty &&
      mutation({
        variables: data,
      })
        .then(({data}) => {
          setSuccess(true);
          setData(data[extract]);
        })
        .catch((errors) => {
          setSuccess(false);
          setError(errors.message.split('GraphQL error: '));
        });
  };

  return {submitForm, loading, error, success, data};
}
