import React from 'react';
import SetupMerchant from 'Screens/Setup/Profile';
import Account from 'Screens/StartMerchant/Account';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import Headers from '../Headers';

export default function Setup() {
  let match = useRouteMatch();
  return (
    <Router>
      <Headers>
        <Headers.Setup />
      </Headers>
      <Switch>
        <Route exact path={`/`}>
          <Redirect to={`${match.path}setupmerchant`} />
        </Route>
        <Route exact path={`${match.path}setupmerchant`}>
          <SetupMerchant />
        </Route>
        <Route exact path={`${match.path}account`}>
          <Account />
        </Route>
      </Switch>
    </Router>
  );
}
