import React, {useEffect} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {GET_USER, EDIT_USER} from './UserDetails.graph';
import {
  CenterLeft,
  Padding,
  Margin,
  useThemeContext,
  Stretch,
  Input,
  FullScreen,
  Center,
  Loader,
  Icon,
  H2,
  H3,
  RoundBtn,
  Row,
  MarginHorizontal,
} from '@servesall/atoms';
import {useForm, Controller} from 'react-hook-form';
import useSubmitHook from 'Hooks/SubmitForm';

export default function UserDetails() {
  const {color7, color2, color1, color6} = useThemeContext();
  const {data = {get_user: {}}} = useQuery(GET_USER);

  const {control, handleSubmit, errors, formState, reset} = useForm({
    mode: 'onChange',
    defaultValues: {
      full_name: '...',
      email: '...',
      full_phone_number: '...',
    },
  });

  const {submitForm, loading, error} = useSubmitHook({
    gql: EDIT_USER,
    formState,
  });

  useEffect(() => {
    Object.keys(data.get_user).length > 0 &&
      reset({
        full_name: data.get_user.full_name,
        email: data.get_user.email,
        full_phone_number: data.get_user.full_phone_number,
      });
  }, [data]);

  const editUser = (data) => {
    submitForm({data: {...data}});
  };

  const defaultProps = ({value, onChange, ref, error}) => {
    return {
      keyboardVerticalOffset: 100,
      borderColorIdle: color7,
      borderColorActive: color2,
      borderColorError: color6,
      value: value,
      inputRef: ref,
      textChange: (value) => onChange(value),
      hasError: error,
    };
  };

  return (
    <CenterLeft style={{flex: 1}}>
      {loading && (
        <FullScreen style={{backgroundColor: color1}}>
          <Center>
            <Loader color={color2} />
          </Center>
        </FullScreen>
      )}
      <Stretch>
        <Margin>
          <Padding>
            <H2>Your Details</H2>
          </Padding>
          {error && (
            <Padding>
              <H2 color={color6}>{error}</H2>
            </Padding>
          )}
          <Padding>
            <Controller
              name="full_name"
              control={control}
              rules={{required: true}}
              render={({onChange, value, ref}) => (
                <Padding>
                  <Input
                    {...defaultProps({
                      value,
                      onChange,
                      ref,
                      error: errors.full_name,
                    })}
                    autoFocus={false}
                    error="Invalid full name">
                    <H3>Full Name</H3>
                  </Input>
                </Padding>
              )}
            />
          </Padding>
          <Padding>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              }}
              render={({onChange, value, ref}) => (
                <Padding>
                  <Input
                    keyboardType="email-address"
                    {...defaultProps({
                      value,
                      onChange,
                      ref,
                      error: errors.email,
                    })}
                    error="Invalid email">
                    <H3>Email</H3>
                  </Input>
                </Padding>
              )}
            />
          </Padding>
          <Padding>
            <Controller
              name="full_phone_number"
              control={control}
              rules={{
                required: false,
              }}
              render={({onChange, value, ref}) => (
                <Padding>
                  <Input
                    keyboardType="number-pad"
                    editable={false}
                    {...defaultProps({
                      value,
                      onChange,
                      ref,
                      error: errors.full_phone_number,
                    })}
                    error="Invalid phone number">
                    <H3>Phone Number</H3>
                  </Input>
                </Padding>
              )}
            />
          </Padding>
          {formState.isDirty &&
            formState.isValid &&
            !formState.isSubmitSuccessful && (
              <Padding>
                <RoundBtn
                  color={color2}
                  onClick={handleSubmit((data) => editUser(data))}>
                  <Center>
                    <Row style={{alignItems: 'center'}}>
                      <MarginHorizontal>
                        <H3 color={color1}>Submit</H3>
                      </MarginHorizontal>
                      <Icon
                        icon="next"
                        autoplay={false}
                        loop={false}
                        color={color1}
                      />
                    </Row>
                  </Center>
                </RoundBtn>
              </Padding>
            )}
        </Margin>
      </Stretch>
    </CenterLeft>
  );
}
