import React, {useEffect} from 'react';
import Login from 'Screens/Auth/Login';
import Register from 'Screens/Auth/Register';
import Otp from 'Screens/Auth/Otp';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import Headers from '../Headers';

export default function Auth({mustRegister}) {
  let match = useRouteMatch();

  return (
    <Router>
      <Headers>
        <Headers.Auth />
      </Headers>
      <Switch>
        <Route exact path={`/`}>
          <Redirect
            to={`${match.path}${mustRegister ? 'register' : 'login'}`}
          />
        </Route>
        <Route exact path={`${match.path}login`}>
          <Login />
        </Route>
        <Route exact path={`${match.path}register`}>
          <Register />
        </Route>
        <Route exact path={`${match.path}otp`} component={Otp} />
      </Switch>
    </Router>
  );
}
