import React from 'react';
import Auth from './Auth';
import StartMerchant from './StartMerchant';
import Setup from './Setup';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

const Routes = ({children}) => {
  return <Router>{children}</Router>;
};

Routes.Auth = (props) => {
  return (
    <Switch>
      <Auth mustRegister={props.mustRegister} />
    </Switch>
  );
};

Routes.StartMerchant = (props) => {
  return (
    <Switch>
      <StartMerchant />
    </Switch>
  );
};

Routes.Setup = (props) => {
  return (
    <Switch>
      <Setup />
    </Switch>
  );
};

export default Routes;
