import React from 'react';
import {useAuthUpdater, set_tokens} from '@servesall/organisms';
import {
  Padding,
  Center,
  H3,
  RoundBtn,
  Row,
  MarginHorizontal,
  useThemeContext,
  Margin,
} from '@servesall/atoms';

function Logout() {
  const authSetter = useAuthUpdater();
  const {color2, color1} = useThemeContext();
  const logoutUser = () => {
    set_tokens({access_token: 'loggedOut', refresh_token: 'loggedOut'}).then(
      () => {
        authSetter({
          isLoggedIn: false,
          mustRegister: false,
          networkCheck: true,
        });
      },
    );
  };

  return (
    <Margin>
      <Padding>
        <RoundBtn color={color2} onClick={() => logoutUser()}>
          <Center>
            <Row style={{alignItems: 'center'}}>
              <MarginHorizontal>
                <H3 color={color1}>Logout</H3>
              </MarginHorizontal>
            </Row>
          </Center>
        </RoundBtn>
      </Padding>
    </Margin>
  );
}

export default Logout;
