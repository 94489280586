import React from "react";
import './App.css';
import {ConnectProvider, ProfileProvider} from '@servesall/organisms';
import {gqlEndpoint} from './Config';
import { ThemeWrapper } from "@servesall/atoms";
import RouteLogic from 'Routes';
import theme from './Theme';

function App() {
  return (
    <ConnectProvider gqlEndpoint={gqlEndpoint}>
      <ProfileProvider>
      <ThemeWrapper theme={theme}>
        <RouteLogic />
      </ThemeWrapper>
      </ProfileProvider>
    </ConnectProvider>
  );
}

export default App;
