import {gql} from '@apollo/client';

export const GET_USER = gql`
  query get_user {
    get_user {
      full_name
      email
      prefix
      country
      phone_number
      full_phone_number
      active
    }
  }
`;

export const EDIT_USER = gql`
  mutation EditUser($full_name: String!, $email: String!) {
    update_user(full_name: $full_name, email: $email) {
      full_name
      email
      full_phone_number
    }
  }
`;
