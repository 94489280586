import React, {useEffect} from 'react';
import {SafeAreaView, StatusBar, View, ScrollView} from 'react-native';
import {
  CenterLeft,
  Padding,
  Margin,
  MarginVertical,
  H1,
  H3,
  Input,
  useThemeContext,
  Stretch,
  RoundBtn,
  Row,
  Center,
  Loader,
  Icon,
  MarginHorizontal,
  FullScreen,
  WebSmallWrapper,
  H2,
} from '@servesall/atoms';
import {useForm, Controller} from 'react-hook-form';
import {LOGIN_USER_GRAPH} from './Login.graph';
import useSubmitHook from 'Hooks/SubmitForm';
import {useHistory} from 'react-router-dom';

const DEFAULT_PREFIX = '+356';

export default function Login({navigation}) {
  const {color7, color2, color1, color6} = useThemeContext();
  let history = useHistory();

  const {control, handleSubmit, errors, formState} = useForm({
    mode: 'onChange',
  });

  const {submitForm, loading, error, success, data} = useSubmitHook({
    gql: LOGIN_USER_GRAPH,
    formState,
    extract: 'login_user',
  });

  useEffect(() => {
    if (success) {
      history.push({
        pathname: '/otp',
        state: {
          ...data,
        },
      });
    }
  }, [data]);

  const registerUser = (data) => {
    const prefix_int = parseInt(data.prefix.replace('+', '00'));
    const phone_number = `${prefix_int}${data.phoneNumber}`;
    submitForm({data: {phone_number}});
  };

  const defaultProps = ({value, onChange, ref, error}) => {
    return {
      keyboardVerticalOffset: 100,
      borderColorIdle: color7,
      borderColorActive: color2,
      borderColorError: color6,
      value: value,
      inputRef: ref,
      textChange: (value) => onChange(value),
      hasError: error,
    };
  };

  return (
    <View style={{flex: 1, backgroundColor: color1}}>
      <Center style={{flex: 1}}>
        <WebSmallWrapper>
          {loading && (
            <FullScreen style={{backgroundColor: color1}}>
              <Center>
                <Loader color={color2} />
              </Center>
            </FullScreen>
          )}
          <CenterLeft style={{flex: 1}}>
            <Stretch>
              <Margin>
                <MarginVertical>
                  <Padding>
                    <H1>Login</H1>
                    <H1>to your account</H1>
                  </Padding>
                </MarginVertical>
                {error && (
                  <Padding>
                    <H2 color={color6}>{error}</H2>
                  </Padding>
                )}
                <Padding>
                  <Row>
                    <Stretch style={{flex: 1}}>
                      <Controller
                        name="prefix"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^[0-9 +]+$/,
                        }}
                        defaultValue={DEFAULT_PREFIX}
                        render={({onChange, value, ref}) => (
                          <Input
                            {...defaultProps({
                              value,
                              onChange,
                              ref,
                              error: errors.prefix,
                            })}
                            autoFocus={false}
                            error="Invalid">
                            <H3>Prefix</H3>
                          </Input>
                        )}
                      />
                    </Stretch>
                    <Stretch style={{flex: 3}}>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^\d{5,}$/,
                        }}
                        defaultValue={null}
                        render={({onChange, value, ref}) => (
                          <Input
                            {...defaultProps({
                              value,
                              onChange,
                              ref,
                              error: errors.phoneNumber,
                            })}
                            keyboardType="number-pad"
                            autoFocus={false}
                            error="Invalid phone number">
                            <H3>Phone Number</H3>
                          </Input>
                        )}
                      />
                    </Stretch>
                  </Row>
                </Padding>
              </Margin>
            </Stretch>
          </CenterLeft>
          <Margin>
            <Padding>
              <RoundBtn
                color={color2}
                onClick={handleSubmit((data) => registerUser(data))}>
                <Center>
                  <Row style={{alignItems: 'center'}}>
                    <MarginHorizontal>
                      <H3 color={color1}>Login</H3>
                    </MarginHorizontal>
                    <Icon
                      icon="next"
                      autoplay={false}
                      loop={false}
                      color={color1}
                    />
                  </Row>
                </Center>
              </RoundBtn>
            </Padding>
          </Margin>
        </WebSmallWrapper>
      </Center>
    </View>
  );
}
