import React, {useEffect} from 'react';
import {
  useAuthState,
  useProfileHook,
  useProfileState,
} from '@servesall/organisms';
import Routes from './Routes';

export default function RouteLogic() {
  const {isLoggedIn, mustRegister, networkCheck} = useAuthState();
  const {fetchProfile} = useProfileHook();
  const {profile, hasMerchant} = useProfileState();

  console.log('hasMerchant', hasMerchant);

  useEffect(() => {
    isLoggedIn && fetchProfile();
  }, [isLoggedIn]);

  if (!isLoggedIn && networkCheck) {
    return (
      <Routes>
        <Routes.Auth mustRegister={mustRegister} />
      </Routes>
    );
  }

  if (!hasMerchant && isLoggedIn && networkCheck) {
    return (
      <Routes>
        <Routes.StartMerchant />
      </Routes>
    );
  }

  if (hasMerchant && isLoggedIn && networkCheck) {
    return (
      <Routes>
        <Routes.Setup />
      </Routes>
    );
  }

  return null;
}
