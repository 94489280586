import React from 'react';
import {SafeAreaView, ScrollView} from 'react-native';
import UserDetails from './UserDetails';
import Logout from './Logout';
import Help from './Help';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import {useThemeContext, Center, WebSmallWrapper} from '@servesall/atoms';

export default function Account({navigation}) {
  const {color1} = useThemeContext();
  return (
    <Center style={{flex: 1}}>
      <WebSmallWrapper>
        <ScrollView keyboardShouldPersistTaps="always">
          <UserDetails navigation={navigation} />
          <PrivacyPolicy />
          <TermsAndConditions />
          <Help />
          <Logout navigation={navigation} />
        </ScrollView>
      </WebSmallWrapper>
    </Center>
  );
}
