import React from 'react';
import StartMerchant from 'Screens/StartMerchant/StartMerchant';
import Account from 'Screens/StartMerchant/Account';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import Headers from '../Headers';

export default function Auth() {
  let match = useRouteMatch();
  return (
    <Router>
      <Headers>
        <Headers.StartMerchant />
      </Headers>
      <Switch>
        <Route exact path={`/`}>
          <Redirect to={`${match.path}startmerchant`} />
        </Route>
        <Route exact path={`${match.path}startmerchant`}>
          <StartMerchant />
        </Route>
        <Route exact path={`${match.path}account`}>
          <Account />
        </Route>
      </Switch>
    </Router>
  );
}
