import {gql} from '@apollo/client';

export const LOGIN_USER_GRAPH = gql`
  mutation LoginUser($phone_number: String!) {
    login_user(phone_number: $phone_number) {
      id
      unique_code
      full_phone_number: phone_number
    }
  }
`;
