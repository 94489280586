import React from 'react';
import {
  Padding,
  Margin,
  H1,
  useThemeContext,
  Stretch,
  FullScreen,
  Center,
  Loader,
  H3,
  RoundBtn,
  MarginHorizontal,
  WebSmallWrapper,
} from '@servesall/atoms';
import LottieView from 'lottie-react-native';
import {CREATE_MERCHANT} from './CreateMerchant.graph';
import useSubmitHook from 'Hooks/SubmitForm';
import setupMerchant from './setupMerchant.json';

export default function Account() {
  const {color1, color2} = useThemeContext();
  const {submitForm, loading, error, success, data} = useSubmitHook({
    gql: CREATE_MERCHANT,
    formState: {isValid: true, isDirty: true},
    extract: 'create_merchant',
  });

  if (loading) {
    return (
      <FullScreen style={{backgroundColor: color1}}>
        <Center>
          <Loader color={color2} />
        </Center>
      </FullScreen>
    );
  }

  return (
    <Center style={{flex: 1}}>
      <WebSmallWrapper>
        <Stretch
          style={{
            flex: 1,
            justifyContent: 'space-around',
          }}>
          <LottieView
            style={{
              width: '100%',
            }}
            autoPlay={true}
            loop={true}
            source={setupMerchant}
          />
          <Margin>
            <Padding>
              <H1 align="center">Create your merchant account</H1>
            </Padding>
          </Margin>
          <Margin>
            <Margin>
              <Padding>
                <RoundBtn color={color2} onClick={() => submitForm({data: {}})}>
                  <Center>
                    <MarginHorizontal>
                      <H3 color={color1}>Create Account</H3>
                    </MarginHorizontal>
                  </Center>
                </RoundBtn>
              </Padding>
            </Margin>
          </Margin>
        </Stretch>
      </WebSmallWrapper>
    </Center>
  );
}
