import React, {useEffect} from 'react';
import {SafeAreaView, StatusBar, View, ScrollView} from 'react-native';
import {
  CenterLeft,
  Padding,
  Margin,
  MarginVertical,
  H1,
  H2,
  H3,
  Input,
  useThemeContext,
  Stretch,
  RoundBtn,
  Row,
  Center,
  Loader,
  Icon,
  MarginHorizontal,
  FullScreen,
  WebSmallWrapper,
} from '@servesall/atoms';
import {useForm, Controller} from 'react-hook-form';
import useSubmitHook from 'Hooks/SubmitForm';
import {CREATE_USER_GRAPH} from './Register.graph';
import {useHistory} from 'react-router-dom';

const DEFAULT_PREFIX = '+356';
const DEFAULT_COUNTRY = 'Malta';

export default function Register({navigation}) {
  let history = useHistory();
  const {color7, color2, color1, color6} = useThemeContext();
  const {control, handleSubmit, errors, formState} = useForm({
    mode: 'onChange',
  });
  const {submitForm, loading, error, data} = useSubmitHook({
    gql: CREATE_USER_GRAPH,
    formState,
  });

  useEffect(() => {
    if (data) {
      history.push({
        pathname: '/otp',
        state: {
          ...data,
        },
      });
    }
  }, [data]);

  const registerUser = (data) => {
    const prefix_int = parseInt(data.prefix.replace('+', '00'));
    const userParams = {
      full_name: data.fullName,
      email: data.email,
      phone_number: data.phoneNumber,
      full_phone_number: `${prefix_int}${data.phoneNumber}`,
      prefix: prefix_int,
      country: DEFAULT_COUNTRY,
      active: true,
    };
    submitForm({data: {input: userParams}});
  };

  const defaultProps = ({value, onChange, ref, error}) => {
    return {
      keyboardVerticalOffset: 100,
      borderColorIdle: color7,
      borderColorActive: color2,
      borderColorError: color6,
      value: value,
      inputRef: ref,
      textChange: (value) => onChange(value),
      hasError: error,
    };
  };

  return (
    <View style={{flex: 1, backgroundColor: color1}}>
      <Center style={{flex: 1}}>
        <WebSmallWrapper>
          {loading && (
            <FullScreen style={{backgroundColor: color1}}>
              <Center>
                <Loader color={color2} />
              </Center>
            </FullScreen>
          )}
          <CenterLeft style={{flex: 1}}>
            <Stretch>
              <Margin>
                <MarginVertical>
                  <Padding>
                    <H1>Register</H1>
                    <H1>an account now</H1>
                  </Padding>
                </MarginVertical>
                {error && (
                  <Padding>
                    <H2 color={color6}>{error}</H2>
                  </Padding>
                )}
                <Controller
                  name="fullName"
                  control={control}
                  rules={{required: true}}
                  defaultValue={null}
                  render={({onChange, value, ref}) => (
                    <Padding>
                      <Input
                        autoFocus={true}
                        {...defaultProps({
                          value,
                          onChange,
                          ref,
                          error: errors.fullName,
                        })}
                        error="Invalid full name">
                        <H3>Full Name</H3>
                      </Input>
                    </Padding>
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  }}
                  defaultValue={null}
                  render={({onChange, value, ref}) => (
                    <Padding>
                      <Input
                        keyboardType="email-address"
                        {...defaultProps({
                          value,
                          onChange,
                          ref,
                          error: errors.email,
                        })}
                        error="Invalid email">
                        <H3>Email</H3>
                      </Input>
                    </Padding>
                  )}
                />
                <Padding>
                  <Row>
                    <Stretch style={{flex: 1}}>
                      <Controller
                        name="prefix"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^[0-9 +]+$/,
                        }}
                        defaultValue={DEFAULT_PREFIX}
                        render={({onChange, value, ref}) => (
                          <Input
                            {...defaultProps({
                              value,
                              onChange,
                              ref,
                              error: errors.prefix,
                            })}
                            error="Invalid">
                            <H3>Prefix</H3>
                          </Input>
                        )}
                      />
                    </Stretch>
                    <Stretch style={{flex: 3}}>
                      <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                          required: true,
                          pattern: /^\d{5,}$/,
                        }}
                        defaultValue={null}
                        render={({onChange, value, ref}) => (
                          <Input
                            keyboardType="number-pad"
                            {...defaultProps({
                              value,
                              onChange,
                              ref,
                              error: errors.phoneNumber,
                            })}
                            error="Invalid phone number">
                            <H3>Phone Number</H3>
                          </Input>
                        )}
                      />
                    </Stretch>
                  </Row>
                </Padding>
              </Margin>
            </Stretch>
          </CenterLeft>
          <Margin>
            <Padding>
              <RoundBtn
                color={color2}
                onClick={handleSubmit((data) => registerUser(data))}>
                <Center>
                  <Row style={{alignItems: 'center'}}>
                    <MarginHorizontal>
                      <H3 color={color1}>Register</H3>
                    </MarginHorizontal>
                    <Icon
                      icon="next"
                      autoplay={false}
                      loop={false}
                      color={color1}
                    />
                  </Row>
                </Center>
              </RoundBtn>
            </Padding>
          </Margin>
        </WebSmallWrapper>
      </Center>
    </View>
  );
}
